var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "Daily" } },
            [
              _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.tableData,
                  fields: _vm.dailyFields,
                  "select-mode": "single",
                  "selected-variant": "success",
                  tdClass: "bv-table-data",
                  "th-class": "bv-table-header"
                }
              }),
              !_vm.noData
                ? _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.exportCSV(_vm.tableData, "Daily")
                        }
                      }
                    },
                    [
                      _c("i", { class: _vm.$config.icons.stats.exportCSVFile }),
                      _c("br"),
                      _vm._v(" Export as CSV ")
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Total" } },
            [
              _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.summaryData,
                  fields: _vm.summaryFields,
                  "select-mode": "single",
                  "selected-variant": "success",
                  tdClass: "bv-table-data",
                  "th-class": "bv-table-header"
                }
              }),
              !_vm.noData
                ? _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.exportCSV(_vm.summaryData, "Summary")
                        }
                      }
                    },
                    [
                      _c("i", { class: _vm.$config.icons.stats.exportCSVFile }),
                      _c("br"),
                      _vm._v(" Export as CSV ")
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v("Values shown are combined across all selected devices.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }