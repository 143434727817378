<script>
import { Bar, mixins } from 'vue-chartjs'
export default {
  extends: Bar,
  name: 'bar-graph',
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
